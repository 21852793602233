import React, { useState } from "react";
import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import "./HospitalDetails.css";
import imghA from "./Assets/Hospital A.png";
import imghB from "./Assets/Hospital B.png";
import vid11 from "./Assets/vid/hspvid.mp4";

const HospitalDetails = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };
  return (
    <>
      <Header />
      <div className="container my-lg-4">
        <div className="row">
          <div className="col">
            <h5 className="text-center py-2">
              Understand the details of the given exercise below. Once you're
              ready, proceed to apply what you've learned.
            </h5>
          </div>
        </div>
        <div className="row">
          <div className="col hostal_bg py-4 px-5">
            <h3 className="fw-bold">Which hospital would be your choice:</h3>
            <p>
              Imagine you need to choose between two hospitals for an elderly
              relative surgery. <br /> Out of each hospital’s last 1000
              patient’s, 900 survived in hospital A while only 800 survived at
              Hospital B. <br /> So it looks like Hospital A is the better
              choice.
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col hspt_imgs text-center">
            <img src={imghA} alt="imghA" className="img-fluid" />
            <img src={imghB} alt="imghB" className="img-fluid" />
          </div>
        </div>
        <div className="row my-lg-5">
          <div className="col hsp_para">
            <p>
              But before you make your decision, remember that not all patients
              arrive at the hospital with the same level of health. And if we
              divide each hospital’s last 1000 patients into those who arrived
              in good health and those who arrived in poor health, the picture
              starts to look very different.
            </p>
            <p className="">
              Hospital A had inly 100 patients, who arrived in poor health, of
              which 30 survived. <br />
              But Hospital B had 400, and they were able to save 210.{" "}
            </p>
            <div className="d-flex align-items-center my-lg-5">
              <p className="hsp_submit me-2 mb-0">Submit your answer here:</p>
              
              <div className="col-lg-4">
              <div className="input-group px-2 d-flex align-items-center">
                <select
                  className="form-select text-muted"
                  onChange={handleSelectChange}
                  value={selectedOption}
                >
                  <option value="" disabled>
                    ---Select---
                  </option>
                  <option value="Hospital A">Hospital A</option>
                  <option value="Hospital B">Hospital B</option>
                </select>
                {selectedOption && (
                  <span className="ms-2">
                    {selectedOption === "Hospital A" ? (
                      <i
                        className="bi bi-x-circle-fill text-danger"
                        style={{ fontSize: "1.5rem" }}
                      ></i>
                    ) : (
                      <i
                        className="bi bi-check-circle-fill text-success"
                        style={{ fontSize: "1.5rem" }}
                      ></i>
                    )}
                  </span>
                )}
              </div>
              </div>
             
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col hsp_para">
            <p>
              So Hospital B is the better choice for patients who arrive at
              hospital in poor health, with a survival rate of 52.5%. And what
              if your relative’s health is good when she arrives at the
              hospital? Strangely enough, Hospital B is still the better choice,
              with a survival rate of over 98%.
            </p>
            <h2 className="fw-bold my-5">
              So how can Hospital A have a better overall survival rate if
              Hospital B has better survival rates for patients in each of the
              two groups?
            </h2>
          </div>
        </div>
      </div>

      <div className="container hsp_container_bg">
        <div className="row align-items-center py-5">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="video-wrapper">
              <video src={vid11} className="w-100" autoPlay muted loop></video>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-12">
            <div className="content-wrapper hsp_para">
              <p>
                Statistics are persuasive so much so that people, organizations,
                and whole countries base some of their important decisions on
                organized data.
              </p>
              <p className="hsp_submit fw-bold">
                But there’s a problem with that.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-lg-5">
        <div className="row my-lg-2">
          <h1 className="fw-bold py-1">How statistics can be misleading</h1>
          <div className="col hsp_para mt-3">
            <p>
              What we’ve stumbled upon is a case of{" "}
              <span className="fw-bold">Simpson’s paradox</span> , where the
              same set of data can appear to show opposite trends depending on
              how it’s grouped. This often occurs when aggregated data hides a
              conditional variable, sometimes known as a lurking variable, which
              is a hidden additional factor that significantly influences
              results.
            </p>
            <p>
              {" "}
              Here, the hidden factor is the relative proportion of patients who
              arrive in good or poor health
            </p>
            <p>
              Simpson’s paradox isn’t just a hypothetical scenario. It pops up
              from time to time in the real world, sometimes in important
              contexts.
            </p>
            <p>
              One study in the UK appeared to show that smokers had a higher
              survival rate than non smokers over a twenty-year time period.
              That is, until dividing the participants by age group showed that
              the nonsmokers were significantly older on average, and thus, more
              likely to die during the trial period, precisely because they were
              living longer in general.
            </p>
            <p>
              Here, the age groups are the lurking variable, and are vital to
              correctly interpret the data.
            </p>
            <p>
              In another example, an analysis in Florida’s death penalty cases
              seemed to reveal no racial disparity in sentencing between black
              and white defendants convicted of murder. But dividing the cases
              by the race of the victim told a different story.
            </p>
            <p>
              In either situation, black defendants were more likely to be
              sentenced to death. The slightly higher overall sentencing rate
              for white defendants was due to the fact that cases with white
              victims were more likely to elicit a death sentence that cases
              where the victim was black, and most murders occurred between
              people of the same race.
            </p>
            <p className="fw-bold">
              So how do we avoid falling for the paradox?
            </p>
            <p>
              Data can be grouped and divided in any number of ways, and overall
              numbers may sometimes give a more accurate picture than data
              divided into misleading or arbitrary categories. All we can do is
              carefully study the actual situations that statistics describe and
              consider whether lurking variable may be present. Otherwise, we
              leave ourselves vulnerable to those who would use data to
              manipulate others and promote their own agendas.
            </p>
            <div className="my-5">
              <h4 className="fw-bold">Citation</h4>
              <p>Lesson by Mark Liddell.</p>
              <p>
                Click here to learn more about this topic{" "}
                <a
                  href="https://youtu.be/sxYrzzy3cq8?si=dnCKCd6I3FzFIUro"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://youtu.be/sxYrzzy3cq8?si=dnCKCd6I3FzFIUro
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default HospitalDetails;
