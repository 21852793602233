import React from "react";
import "./FirstPage.css";
import blurImg from "./Assets/Why Analytics Blurred Image.png";
import vector2 from "./Assets/Vector 2.png";
import videowithLamp from "./Assets/Video with Lamp.mp4";

const FirstPage = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="First_1">
            <img src={blurImg} alt="" />
            <video src={videowithLamp} loop autoPlay muted>
              Your browser does not support HTML video.
            </video>
          </div>

          <div className="row">
            <h1 className="First_1-1">
              At <span>Stephen’s</span> Analytics
            </h1>
            <div className="text-center connect_font_size mt-lg-4 mt-md-1 ">
              <h2 className="fw-bold">
                Connect your insights to
                <span class="badge text_bg_results mx-1">results</span>
              </h2>
              <h2 className="fw-bold">
                and make your{" "}
                <span class="badge text_bg_results mx-1">
                  data work for you
                </span>
              </h2>
            </div>
            {/* <img src={vector2} alt="Vector 2" className="First_1-2" /> */}
          </div>
       <span className="d-none d-lg-block"><hr className="First_hrTag" /></span>   
        </div>
      </div>
    </>
  );
};

export default FirstPage;
