import React from "react";
import "./Apply.css"
const Applytoday = () => {
  return (
    <>
      <div className="connect-me">
        <form className="container mt-4">
          <h2 className="textprimary py-lg-3">
            Start your Stephen's Pilot today
          </h2>
          <div className="row ">
            <div className="col-md-6">
              <label htmlFor="firstName" className="form-label mt-3">
                First Name:<span className="text-danger fw-bold">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                name="firstName"
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="company" className="form-label  mt-3">
                Company:
              </label>
              <input
                type="text"
                className="form-control"
                id="company"
                name="company"
              />
            </div>
          </div>

          <div className="row ">
            <div className="col-md-6 ">
              <label htmlFor="email" className="form-label mt-3">
                Work Email Address: <span className="text-danger fw-bold">*</span>
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
              />
            </div>
            <div className="col-md-6 ">
              <label htmlFor="officeNumber" className="form-label  mt-3">
                Office Number: <span className="text-danger fw-bold">*</span> 
              </label>
              <input
                type="text"
                className="form-control"
                id="officeNumber"
                name="officeNumber"
              />
            </div>
          </div>

          <div className="mt-3">
            <label htmlFor="message" className="form-label">
              Message:
            </label>
            <textarea
              className="form-control"
              id="message"
              name="message"
              rows="3"
            ></textarea>
          </div>

          <button type="submit" className="custombtn mt-3">
            Connect me
          </button>
        </form>
      </div>
    </>
  );
};

export default Applytoday;
