import React, { useState } from "react";
import "./banking.css";
import BankingVideo from "./assets/BankingVideo.mp4";
import icon1 from "./assets/Icon 1.png";
import icon2 from "./assets/Icon 2.png";
import icon3 from "./assets/Icon 3.png";
import icon4 from "./assets/Icon 4.png";
import Table from "./assets/Table.png";
import Header from "../../../../../Component/Header/Header";
import Footer from "../../../../../Component/Footer/Footer";
import News from "../../../../../Component/News/News";
import Applytoday from "../../../../../Component/Applytoday/Applytoday";

const Banking = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    company: "",
    email: "",
    officeNumber: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log("Form submitted:", formData);
  };

  return (
    <>
      <Header />
      <div className="container">
        <div className="row mt-lg-5">
          <div className="video-container">
            <video className="video1" src={BankingVideo} loop autoPlay muted>
              Your browser does not support HTML video.
            </video>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-7 col-md-12">
            <div className="boostingBank mt-lg-3">
              <h4 style={{ fontWeight: "bold" }}>
                Boosting Bank Performance: How Analytics Drives Efficiency and
                Growth
              </h4>
              <p style={{ textAlign: "justify" }}>
                In an era where financial security is paramount, banks utilise
                advanced analytics to optimise their operations, cut costs, and
                improve decision- making. This results in enhanced operational
                efficiency and sustained growth, positioning banks competitively
                in a rapidly evolving industry.
              </p>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 md-1 mt-lg-3">
            <div className="color_fin">
              <h1 className="color180">180%</h1>
              <p style={{ textAlign: "justify" }}>
                Customer satisfaction is 180% more likely to{" "}
                <span className="d-none d-lg-inline">
                  <br />
                </span>
                improve in financial institutions that focus{" "}
                <span className="d-none d-lg-inline">
                  <br />
                </span>{" "}
                on experience-driven engagements.{" "}
                <a
                  href="#bibliography"
                  onClick={() => {
                    document
                      .getElementById("one")
                      .scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  [1]
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="row my-4">
          <div className="col-12 heading">
            <h5>Current movements in the banking sector</h5>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-6 col-md-12 d-flex flex-row mb-3">
            <div className="col-3">
              <img
                src={icon1}
                alt="icon1"
                className="icon_1 mx-lg-5 mx-md-5 mx-sm-2 img-fluid"
              />
            </div>
            <div className="col-9 ">
              62%{" "}
              <a
                href="#bibliography"
                onClick={() => {
                  document
                    .getElementById("two")
                    .scrollIntoView({ behavior: "smooth" });
                }}
              >
                [2]
              </a>{" "}
              of financial service workers say the employee experience
              positively impacts their ability to serve customers, renewing the
              priority on enhancing employee experiences.
            </div>
          </div>

          <div className="col-lg-6 col-md-12 d-flex flex-row mb-3">
            <div className="col-3">
              <img
                src={icon2}
                alt="icon2"
                className="icon1 mx-lg-5 mx-md-5 mx-sm-2 img-fluid"
              />
            </div>
            <div className="col-9">
              Machine learning, process mining, process automation, and API
              integrations are increasingly being adopted to streamline
              complexity through end-to-end automation.
            </div>
          </div>

          <hr className="mt-4" />

          <div className="col-lg-6 col-md-12 d-flex flex-row mt-lg-3">
            <div className="col-3">
              <img
                src={icon3}
                alt="icon3"
                className="icon1 mx-lg-5 mx-md-5 mx-sm-2 img-fluid"
              />
            </div>
            <div className="col-9">
              Following a staggering 1,318% increase in ransomware attacks on
              banks during the first half of 2021, there has been a surge in the
              deployment of endpoint security solutions.{" "}
              <a
                href="#bibliography"
                onClick={() => {
                  document
                    .getElementById("three")
                    .scrollIntoView({ behavior: "smooth" });
                }}
              >
                [3]
              </a>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 d-flex flex-row mt-3">
            <div className="col-3">
              <img
                src={icon4}
                alt="icon4"
                className="icon1 mx-lg-5 mx-md-5 mx-sm-3 img-fluid"
              />
            </div>
            <div className="col-9">
              The push to maintain pre-pandemic cloud momentum is pushing the
              need to modernise legacy applications and shorten service launch
              timeframes.
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div>
            <h4 style={{ fontWeight: "bolder" }}>Let's take a closer look.</h4>
            <h5 className="mb-4">
              Consider three recent examples of the power of analytics in
              banking:
            </h5>
            <ul>
              <li className="mb-2">
                To counter a shrinking customer base, a European bank tried a
                number of retention techniques focusing on inactive customers,
                but without significant results. Then it turned to
                machine-learning algorithms that predict which currently active
                customers are likely to reduce their business with the bank.
                This new understanding gave rise to a targeted campaign that
                reduced churn by 15 percent.
              </li>
              <li className="mb-2">
                A US bank used machine learning to study the discounts its
                private bankers were offering to customers. Bankers claimed that
                they offered them only to valuable ones and more than made up
                for them with other, high-margin business. The analytics showed
                something different: patterns of unnecessary discounts that
                could easily be corrected. After the unit adopted the changes,
                revenues rose by 8 percent within a few months.
              </li>
              <li>
                A top consumer bank in Asia enjoyed a large market share but
                lagged behind its competitors in products per customer. It used
                advanced analytics to explore several sets of big data: customer
                demographics and key characteristics, products held, credit-card
                statements, transaction and point-of-sale data, online and
                mobile transfers and payments, and credit-bureau data. The bank
                discovered unsuspected similarities that allowed it to define
                15,000 micro-segments in its customer base. It then built a
                next-product-to-buy model that increased the likelihood to buy
                three times over.{" "}
                <a
                  href="#bibliography4"
                  onClick={() => {
                    document
                      .getElementById("foure")
                      .scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  [4]
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="row mt-4">
          <div className="img_intable">
            <h4 style={{ fontWeight: "bolder" }}>
              Ensuring impact has five prerequisites.
            </h4>
            <h5 className="mb-4">
              An prerequisites ordered approach to data-analytic enlightenment
            </h5>
            <img src={Table} alt="table" className="img-fluid table_image" />
          </div>
        </div>

        <div className="row mt-3">
          <h5 id="bibliography" className="mt-lg-5">
            Bibliography
          </h5>{" "}
          {/* Bibliography anchor here */}
          <ul className="mt-3 mx-lg-3" style={{ listStyleType: "none" }}>
            <li className="my-lg-3" id="one">
              [1] The Financial Brand, Go Beyond ROI with 'Return on Experience'
              with Banking, June 2021{" "}
            </li>
            <li className="my-lg-3" id="two">
              [2] Eagle Hill Consulting, For financial services companies,
              taking action on employee experience will elevate their customer
              experience too, 2021{" "}
            </li>
            <li className="my-lg-3" id="three">
              [3] Trend Micro, Attacks from All Angles: 2021 Midyear Security
              Roundup, September 2021{" "}
            </li>
            <li className="my-lg-2" id="foure">
              [4] ] Study by Amit Garg, Davide Grande, Gloria Macías-Lizaso
              Miranda, Christoph Sporleder, and Eckart Windhagen.
              https://www.mckinsey.com/industries/financial-services/our-insights/analytics-in-banking-time-to-realize-the-value#/
            </li>
          </ul>
        </div>

        {/* <div className="container">
          <div className="row">
            <div className="connect-me1">
              <form className="container mt-4">
                <h2 className="textprimary">
                  Start your Stephen's Pilot today
                </h2>
                <p className="delivery1">
                  We're certain we excel in delivering exceptional results. This
                  could be a pivotal shift for your business. Reach out to{" "}
                  <br /> us today, schedule a call where we'll demonstrate how
                  our solutions can address your challenges & boost your ROI.
                </p>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="firstName" className="form-label">
                      First Name: *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      name="firstName"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="company" className="form-label">
                      Company: *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="company"
                      name="company"
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="email" className="form-label">
                      Work Email Address: *
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="officeNumber" className="form-label">
                      Office Number: *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="officeNumber"
                      name="officeNumber"
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    Message: *
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    rows="1"
                  ></textarea>
                </div>

                <button type="submit" className="custombtn">
                  Connect me
                </button>
              </form>
            </div>
          </div>
        </div> */}
      </div>
      <Applytoday />

      <News />
      <Footer />
    </>
  );
};

export default Banking;
