import React, { useState } from "react";
import "./ProblemsFile.css";
import img1 from "./Assets/Television-01.png";
import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import img21 from "./Assets/Speaking.png";
import img22 from "./Assets/Hearing.png";
import img23 from "./Assets/Seeing.png";
import img24 from "./Assets/Feeling.png";
import img25 from "./Assets/Doing.png";
import Applytoday from "../../Component/Applytoday/Applytoday";

const ProblemsFile = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const accordionStyles = {
    boxShadow: "none",
    backgroundColor: "transparent",
    borderBottom: "none",
    "&:before": {
      display: "none", // Remove the default Material-UI divider line
    },
    "&.Mui-expanded": {
      margin: 0,
    },
    margin: 0,
  };
  const iconStyles = {
    fontSize: "1.2rem", // Adjust the size as needed
  };
  return (
    <>
      <Header />
      <div className="container">
        <div className="row">
          <section className="problem-solving">
            {/* Image Section */}
            <div className="image-box">
              <img src={img1} alt="Problem Solving Illustration" />
            </div>
            {/* Content Section */}
            <div className="content">
              <h1>
                Problem solving at <strong>Stephen’s Analytics</strong>
              </h1>
              <p>
                How would it impact if we said you could{" "}
                <strong className="fw-bold text-white">
                  double your production?
                </strong>
              </p>
            </div>
          </section>
        </div>
        <div className="row">
          {/* Formula Section */}
          <section className="formula-section">
            <div className="text-center">
              <h2>
                We develop formulas to{" "}
                <span className="highlight">maximise your PROFITS.</span>
              </h2>
              <p className="underline-text">
                Answer the following questions to discover how you can optimise
                your company's performance to its fullest potential.
              </p>
            </div>

            {/* --------------------------------------------------------------------Q1----------------------------------- */}
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={accordionStyles}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? (
                    <RemoveIcon sx={iconStyles} />
                  ) : (
                    <AddIcon sx={iconStyles} />
                  )
                }
              >
                <div className="faq-summary">
                  <Typography className="Qcolor fw-bold">
                    How can we improve operational efficiency?
                  </Typography>
                  {expanded === "panel1" && <hr />}
                </div>
              </AccordionSummary>

              <AccordionDetails className="accordion-content">
                <Typography>
                  <p className="answertag1">
                    {" "}
                    <span className="answarecolor">Answer:</span>Analyze
                    processes and identify areas where efficiency can be
                    increased.
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>
            {/* --------------------------------------------------------------------Q2-----------------------------------  */}
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              sx={accordionStyles}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel2" ? (
                    <RemoveIcon sx={iconStyles} />
                  ) : (
                    <AddIcon sx={iconStyles} />
                  )
                }
              >
                <div className="faq-summary">
                  <Typography className="Qcolor fw-bold">
                    What is the potential return on investment (ROI) for new
                    projects or initiatives?
                  </Typography>
                  {expanded === "panel2" && <hr />}
                </div>
              </AccordionSummary>
              <AccordionDetails className="accordion-content">
                <Typography>
                  <p className="answertag2">
                    <span className="answarecolor">Answer:</span> Assess the
                    expected ROI of proposed projects to guide investment
                    decisions.
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>
            {/* --------------------------------------------------------------------Q3----------------------------------- */}
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              sx={accordionStyles}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel3" ? (
                    <RemoveIcon sx={iconStyles} />
                  ) : (
                    <AddIcon sx={iconStyles} />
                  )
                }
              >
                <div className="faq-summary">
                  <Typography className="Qcolor fw-bold">
                    Which products or services are underperforming, and why?
                  </Typography>
                  {expanded === "panel3" && <hr />}
                </div>
              </AccordionSummary>

              <AccordionDetails className="accordion-content">
                <Typography>
                  <p className="answertag3">
                    {" "}
                    <span className="answarecolor">Answer:</span>
                    Analyse underperforming products or services to understand
                    the reasons behind their lack of success.
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>
            {/* --------------------------------------------------------------------Q4----------------------------------- */}
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
              sx={accordionStyles}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel4" ? (
                    <RemoveIcon sx={iconStyles} />
                  ) : (
                    <AddIcon sx={iconStyles} />
                  )
                }
              >
                <div className="faq-summary">
                  <Typography className="Qcolor fw-bold">
                    How can we better allocate resources to achieve our
                    strategic goals?
                  </Typography>
                  {expanded === "panel4" && <hr />}
                </div>
              </AccordionSummary>

              <AccordionDetails className="accordion-content">
                <Typography>
                  <p className="answertag4">
                    {" "}
                    <span className="answarecolor">Answer:</span>
                    Answer: Analyse underperforming products or services to
                    understand the reasons behind their lack of success.
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>
            {/* --------------------------------------------------------------------Q5----------------------------------- */}
            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
              sx={accordionStyles}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel5" ? (
                    <RemoveIcon sx={iconStyles} />
                  ) : (
                    <AddIcon sx={iconStyles} />
                  )
                }
              >
                <div className="faq-summary">
                  <Typography className="Qcolor fw-bold">
                    Are there any cost-saving opportunities in our supply chain?{" "}
                  </Typography>
                  {expanded === "panel5" && <hr />}
                </div>
              </AccordionSummary>

              <AccordionDetails className="accordion-content">
                <Typography>
                  <p className="answertag5">
                    {" "}
                    <span className="answarecolor">Answer:</span>
                    Analyze the supply chain to identify areas where costs can
                    be reduced without affecting quality.
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>
            {/* --------------------------------------------------------------------Q6----------------------------------- */}
            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
              sx={accordionStyles}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel6" ? (
                    <RemoveIcon sx={iconStyles} />
                  ) : (
                    <AddIcon sx={iconStyles} />
                  )
                }
              >
                <div className="faq-summary">
                  <Typography className="Qcolor fw-bold">
                    What are the root causes of recent declines in sales or
                    revenue?
                  </Typography>
                  {expanded === "panel6" && <hr />}
                </div>
              </AccordionSummary>

              <AccordionDetails className="accordion-content">
                <Typography>
                  <p className="answertag6">
                    {" "}
                    <span className="answarecolor">Answer:</span>
                    Investigate recent performance issues to determine
                    underlying causes.
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>
            {/* --------------------------------------------------------------------Q7----------------------------------- */}

            <Accordion
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
              sx={accordionStyles}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel7" ? (
                    <RemoveIcon sx={iconStyles} />
                  ) : (
                    <AddIcon sx={iconStyles} />
                  )
                }
              >
                <div className="faq-summary">
                  <Typography className="Qcolor fw-bold">
                    What are the most significant predictors of customer
                    satisfaction?
                  </Typography>
                  {expanded === "panel7" && <hr />}
                </div>
              </AccordionSummary>

              <AccordionDetails className="accordion-content">
                <Typography>
                  <p className="answertag7">
                    {" "}
                    <span className="answarecolor">Answer:</span>
                    Investigate the factors most associated with customer
                    satisfaction to improve service and product offerings.
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </section>
        </div>
     
      </div>
      <div className="container-fluid back_prob_color">
      <div className="row mt-5 text-center">
          <h1 className="mb-3 font_mul_hed text-center py-3  highlight my-5">
            We emphasise on asking questions effectively.
          </h1>
          <div className="col-lg-1"></div>

          <div className="col-lg-2 problem_mul_img mt-lg-4">
            <img src={img21} alt="img21" className="img-fluid text-center" />
            <p className=" fs-4">Saying</p>
          </div>
          <div className="col-lg-2 problem_mul_img mt-lg-4">
            <img src={img22} alt="img22" className="img-fluid text-center" />
            <p className="fs-4">Hearing</p>
          </div>
          <div className="col-lg-2 problem_mul_seeing mt-lg-5">
            <img src={img23} alt="img23" className="img-fluid text-center" />
            <p className="text-center fs-4">Seeing</p>
          </div>
          <div className="col-lg-2 problem_mul_seeing mt-lg-5">
            <img src={img24} alt="img24" className="img-fluid text-center" />
            <p className="text-center fs-4">Feeling</p>
          </div>
          <div className="col-lg-2 problem_mul_img mt-lg-4 ">
            <img src={img25} alt="img25" className="img-fluid text-center" />
            <p className=" fs-4">Doing</p>
          </div>
          <div className="col-lg-1"></div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="text_content">
              <p>
                Remember, asking the right questions is not just a skill—it's a
                strategic advantage.
              </p>
              <p>
                It's as crucial as finding the answers themselves. By mastering
                this art, you not only clarify your thought process but also
              </p>
              <p>
                Embrace the impact of insightful questioning and watch how it
                transforms your conversations and decision-making processes.
              </p>
            </div>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-lg-12 back_color_pro py-1">
            <h2 className="py-4 text-center">
              Formulate your capacity with{" "}
              <span className="fw-bold">math, gain</span> and{" "}
              <span className="fw-bold">invest.</span>{" "}
            </h2>
          </div>
        </div>
      </div>
      <Applytoday />
      <Footer />
    </>
  );
};

export default ProblemsFile;
