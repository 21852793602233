import React from "react";
import "./HealthCare.css";

const HealthCareDetail = () => {
  return (
    <>
     
      <div className="container">
        <div className="row">
          <div className="col">
            <h1 className="py-3 htag_h1_bg"> What are the promises of big data analytics in healthcare?</h1>
            <p>
            Healthier patients, lower care costs, more visibility into
            performance, and higher staff and consumer satisfaction rates are
            among the many benefits of turning data assets into data insights.
          </p>
          <p>
            Providers can start to improve their data capture routines by
            prioritising valuable data types – EHRs, genomic data,
            population-level information – for their specific projects,
            enlisting the data governance and integrity expertise of health
            information management professionals, and developing clinical
            documentation improvement programs that coach clinicians about how
            to ensure that data are useful for downstream analytics.
          </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HealthCareDetail;
