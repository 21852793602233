import React from 'react'
import Videoplayer from "./Videoplayer/Videoplayer";
import Powershift from './Powershift/Powershift'
import Problemsolving from './Problemsolving/Problemsolving'
import Slidercontent from './Slidercontent/Slidercontent'

const Home = () => {
  return (
    <>
    <Videoplayer />
   <Powershift />
   <Problemsolving />
   <Slidercontent />
      
    </>
  )
}

export default Home
