import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AllPageImport from "./Pages/AllPageImport/AllPageImport";

import ModernSupplySolutions from "./Pages/Solutions/Services/ModernSupplySolutions/ModernSupplySolutions";
import SolutionPage from "./Pages/Solutions/SolutionsPage/SolutionPage";
import OilandGas from "./Pages/Solutions/Industries/OilandGas/OilandGas";
import InsightsPage from "./Pages/Insights/InsightsPage/InsightsPage";
import ContactUsPage from "./Pages/ContactUs/ContactUsPage/ContactUsPage";
import Open from "./Pages/Solutions/Industries/ViewAll/open/Open";
import CombineDetails from "./Pages/Insights/Blogs/Details/CombineDetails";
import Article from "./Pages/ContactUs/AboutUs/TopNews/Article";
import DataProtection from "./Pages/ContactUs/Help/DataProtection/DataProtection";
import Analytics from "./Pages/Insights/Comprehension/Analytics/Analytics";
import HealthCarePage from "./Pages/HealthCare/HealthCarePage";
import About from "./Pages/Clientele/About";
import JoinOurTeam from "./Pages/JoinOurTeam (1)/JoinOurTeam/JoinOurTeam";
import Banking from "./Pages/Solutions/Industries/banking (1)/banking/Banking";
import Contactusf from "./Pages/Contactus/Contactusf";
import ProblemsFile from "./Pages/ProblemsFile/ProblemsFile";
import ReadMe from "./Pages/ReadMe/ReadMe";
import HospitalDetails from "./Pages/HospitalDetails/HospitalDetails";
import DashBoard from "./Pages/DashBoard/DashBoard";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AllPageImport />} />

          <Route path="/Insights" element={<InsightsPage />} />

          <Route
            path="/ModernSupplySolutions"
            element={<ModernSupplySolutions />}
          />
          <Route path="/SolutionPage" element={<SolutionPage />} />
          <Route path="/Banking" element={<Banking />} />
          <Route path="/OilAndGas" element={<OilandGas />} />
          <Route path="/ViewAll" element={<Open />} />
          <Route path="/Contact" element={<ContactUsPage />} />
          <Route path="/ContactUs" element={<Contactusf />} />
          <Route path="/AdoptingGenAI" element={<CombineDetails />} />
          <Route path="/TopNews" element={<Article />} />
          <Route path="/DataPolicy" element={<DataProtection />} />
          <Route path="/WhyStephensAnyts" element={<Analytics />} />
          <Route path="/HealthCarePage" element={<HealthCarePage />} />
          <Route path="/Clientele" element={<About />} />
          <Route path="/ApplyJobs" element={<JoinOurTeam />} />
          <Route path="/ProblemSolvingsNew" element={<ProblemsFile />} />
          <Route path="/ReadMe" element={<ReadMe />} />
          <Route path="/HospitalDetails" element={<HospitalDetails />} />
          <Route path="/DashBoard" element={<DashBoard />} />



        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
