import React from "react";
import "./HealthCare1.css";

const HealthCareImages = () => {
  return (
    <>
      <div className="container-fluid  my-lg-5" id="container_id">
        <div className="slidess mx-lg-2">
          <div className="text-overlay">
            <p className="text_para">
              Ask: How many patients went to the hospital last week?
            </p>
          </div>
          {/* Bottom */}
          <div className="bg_color_hover">
            <div className="px-2 bottom_img">
              <h5 className="">Descriptive Analytics</h5>
              <p className="mar_top1 hidden-content">
                Gaining insights into past data, trends, and events
              </p>
            </div>
          </div>
        </div>

        {/* --------- */}

        <div className="slidess mx-lg-2">
          <div className="text-overlay">
            <p className="text_para">
            Ask : Why did these patients visit the hospital last week ?            </p>
          </div>
          {/* Bottom */}
          <div className="bg_color_hover">
            <div className="px-3 bottom_img">
              <h5 className="">Diagnostic Analytics</h5>
              <p className="mar_top1 hidden-content">
              Gaining insights into identifying new treatments, disease patterns, and patient risk factors.              </p>
            </div>
          </div>
        </div>
        {/* ---------- */}
        <div className="slidess mx-lg-2">
          <div className="text-overlay">
            <p className="text_para">
            Ask : Which patients will visit the hospital the following next week ?            </p>
          </div>
          {/* Bottom */}
          <div className="bg_color_hover">
            <div className="px-3 bottom_img">
              <h5 className="">Predictive Analytics</h5>
              <p className="mar_top1 hidden-content">
              Predicting patient outcomes, patients at risk and resource allocation.              </p>
            </div>
          </div>
        </div>
        {/* ---- */}
        <div className="slidess mx-lg-2">
          <div className="text-overlay">
            <p className="text_para px-3">
            Ask : These patients should get an extra treatment to prevent a hospitalisation            </p>
          </div>
          {/* Bottom */}
          <div className="bg_color_hover">
            <div className="px-1 bottom_img">
              <h5 className="">Prescriptive Analytics</h5>
              <p className="mar_top1 hidden-content">
              Gaining insights into what course of action to take in patient care.              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HealthCareImages;
