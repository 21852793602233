import React from "react";
import "./DashBoard.css";
import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import vid12 from "./Assets/designlogo.mp4";
import Movvid1 from "./Assets/1.mov";
import Movvid2 from "./Assets/2.mov";
import Movvid3 from "./Assets/3.mov";
import Applytoday from "../../Component/Applytoday/Applytoday";

const DashBoard = () => {
  return (
    <>
      <Header />
      <div className="container-fluid dashboard_bg py-5">
        <div className="row">
          <div className="col">
            <div className="container">
              <div className="row bg_black  pt-1">
                <div className="col-lg-6 col-md-12 col-12">
                  <video
                    src={vid12}
                    className="w-100 py-1"
                    autoPlay
                    muted
                    loop
                  ></video>
                </div>
                <div className="col-lg-6 col-md-12 col-12 d-flex align-items-center justify-content-center">
                  <div className="width_cover">
                    <p>Harvard Business Review</p>
                    <p className="fw-bold">The evolution of Design Thinking.</p>
                    <p>
                      It’s no longer just for products. Executives are using
                      this approach to devise strategy and manage change.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row ">
                <h3 className="text-center my-2 py-2 mt-3">
                  Welcome to visualisation. Let’s bring your{" "}
                  <button className="readmeplaybook">New-Gen Ai toy.</button> to
                  life
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-lg-5 pt-lg-4">
        <div className="row">
          <h3 className="why_design py-lg-3">Why Design Thinking Works.</h3>
          <div className="col width_reducedashboard">
            <p className="">
              <span className="fw-bold">
                Design shall matter when you would like your org to use your new
                tools.
              </span>{" "}
              <br />
              While we know a lot about practices that stimulate new ideas,
              innovation teams often struggle to apply them. <br /> Why? Because
              people’s biases and entrenched behaviors get in the way.
            </p>
            <p>
              Occasionally, a new way of organising work leads to extraordinary
              improvements. <br />
              With our dashboards, you gain a powerful tool that drives informed
              decision-making and enhances your business performance. Experience
              the difference with a dashboard that truly works for you
            </p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <h5 className="mt-3 text-muted">
              Click here to know what{" "}
              <span className="fw-bold">Harvard Business</span> says about user
              interface.
            </h5>
          </div>
        </div>
      </div>
      <div className="container-fluid dash_bg_green my-5">
        <div className="row ">
          <div className="col">
            <div className="container">
              <div className="row">
                <div className="col">
                  <p className="fs-2 py-5">
                    We focus on recreating game-changing products that invoke a
                    sense of excitement, closeness & satisfaction for the user.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-2">
        <div className="row ">
          <h4 className="blue_heading mt-lg-5">
            The Problem-Solving Nature of UX
          </h4>
          <div className="col width_reducedashboard">
            <p>
              User interface (UI) establishes the first impression, it is the
              user experience (UX) design that determines whether you can
              sustain the first impression.
            </p>
            <p>
              While not always quantifiable, it is the key factor in a
              real-world evaluation of a user experience. It is in the
              organisation's best interest to maximise its users' QoE.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid vid_back_blue my-5">
        <div className="row mt-lg-5">
          <div className="col">
            <div className="container">
              <div className="row py-5 my-2 mb-5">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 ">
                      <video
                        src={Movvid1}
                        className="w-100 "
                        autoPlay
                        muted
                        loop
                      ></video>
                    </div>
                    <div className="col-lg-12">
                      <video
                        src={Movvid2}
                        className="w-100 "
                        autoPlay
                        muted
                        loop
                      ></video>
                    </div>
                    <div className="col-lg-12">
                      <video
                        src={Movvid3}
                        className="w-100 "
                        autoPlay
                        muted
                        loop
                      ></video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Applytoday />
      <Footer />
    </>
  );
};

export default DashBoard;
