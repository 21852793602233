import React from "react";
import "./ReadMe.css";
import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import redmeimg from "./Assets/Pamplet-05.png";

const ReadMe = () => {
  return (
    <>
      <Header />
      <div className="container">
        <div className="row mt-lg-4 py-2">
          <div className="col-lg-12">
            <h1 className="fw-bold text-center">
              in case, the website get’s boring,{" "}
              <span className="readmee">read me.</span>
            </h1>
          </div>
        </div>
        <div className="row my-lg-4">
          <div className="col-lg-2"></div>
          <div className="col-lg-4 col-md-12 col-12">
            <label htmlFor="firstName" className="form-label mt-3">
              First Name:<span className="text-danger fw-bold">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              name="firstName"
            />
          </div>
          <div className="col-lg-4 col-md-12 col-12">
            <label htmlFor="firstName" className="form-label mt-3">
              Contact:<span className="text-danger fw-bold">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              name="firstName"
            />
          </div>
          <div className="col-lg-2"></div>
        </div>

        <div className="row">
          <div className="col">
            <h6 className="text-center mt-4">
              We would like you to enjoy your experience at Stephen’s House of
              Analytics.{" "}
           <a href="/DashboardExperience.pdf" download> <button className="readmeplaybook ">Download Playbook</button></a>  
            </h6>
          </div>
        </div>
        
      </div>
      <div className="container-fluid">
        <div className="row my-5 text-center">
          <div className="mt-3">
            <img src={redmeimg} alt="" className="img-fluid" />
          </div>
        </div>
      </div>

      <div className="container my-lg-5">
        <div className="row my-5">
          <div className="col my-lg-5">
            <h5 className="text-center my-2 py-2">
              Sit back and relax while we get your{" "}
              <button className="readmeplaybook">New-Gen Ai toy.</button>
            </h5>
            <h1 className="text-center py-3">Thus, the Power Shift to you.</h1>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ReadMe;
