import React from "react";
import Picture from "./assets/Picture 2.png";
import "./HealthCare.css";

const HealthCareDetails = () => {
  return (
    <>
      <div className="container bg_card_clr ">
        <div className="row my-1 analysis_bg ">
          <div className="col">
            <h1 className="py-2">
              Our team is ready to help you realise the full potential of
              intelligent data.
            </h1>
          </div>
        </div>
        <div className="row mt-lg-5">
  {/* Image Column */}
  <div className="col-lg-6 col-md-12 col-12 ">
    <img src={Picture} alt="Picture" className="img-fluid" />
  </div>

  {/* Text Content Column */}
  <div className="col-lg-6 col-md-12 col-12 d-flex justify-content-center align-items-center">
    <div className="text-center">
      <h2 className="htag_h1_bg">Use Evidence-Based Data!</h2>
      <p>
        Evidence-based practices will, over time, increase efficiencies and
        help sharpen our understanding of the best practices associated with
        any disease, injury, or illness.
      </p>
      <button className="btn analysis_btn px-5 mt-3">
        Start your analysis here.
      </button>
    </div>
  </div>
</div>

      </div>
    </>
  );
};

export default HealthCareDetails;
