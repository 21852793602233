import React, { useState } from "react";
import img1 from "../../Component/Header/Assets/SHOD Logo-01.png";
import "./Header.css";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeContent, setActiveContent] = useState("");
  const [isVisible, setIsVisible] = useState(true);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClick = (contentName) => {
    setActiveContent(contentName);
  };

  const closeOverlay = () => {
    setActiveContent(""); // Reset active content to close overlay
  };

  const [isSolutionsOpen, setSolutionsOpen] = useState(false);
  const [isNestedOpen, setNestedOpen] = useState(false);
  const [isNestedOpenIndustries, setNestedOpenIndustries] = useState(false);

  const [isInsightOpen, setInsightOpen] = useState(false);
  const [isNestedComprehenOpen, setNestedComprehenOpen] = useState(false);
  const [isNestedOpenBlog, setNestedOpenBlog] = useState(false);

  const [isContactOpen, setContactOpen] = useState(false);
  const [isNestedAbout, setNestedAbout] = useState(false);
  const [isNestedOpenCarrier, setNestedOpenCarrier] = useState(false);
  const [isNestedOpenHelp, setNestedOpenHelp] = useState(false);

  const toggleSolutions = () => setSolutionsOpen(!isSolutionsOpen);
  const toggleNestedServices = () => setNestedOpen(!isNestedOpen);
  const toggleNestedIndustries = () =>
    setNestedOpenIndustries(!isNestedOpenIndustries);

  const toggleInsight = () => setInsightOpen(!isInsightOpen);
  const toggleNestedComprehen = () =>
    setNestedComprehenOpen(!isNestedComprehenOpen);
  const toggleNestedBlog = () => setNestedOpenBlog(!isNestedOpenBlog);

  const toggleContact = () => setContactOpen(!isContactOpen);
  const toggleNestedAbout = () => setNestedAbout(!isNestedAbout);
  const toggleNestedCarrier = () => setNestedOpenCarrier(!isNestedOpenCarrier);
  const toggleNestedHelp = () => setNestedOpenHelp(!isNestedOpenHelp);

  return (
    <div className="container container12">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12 Header_Contant my-3">
          <div className="img_logo">
            <a href="/">
              <img src={img1} alt="Logo" />
            </a>
          </div>

          <div
            className={`offcanvas-menu blue_background ${
              isMenuOpen ? "open" : ""
            }`}
          >
            <div className="offcanvas-header">
              <span className="close-button" onClick={toggleMenu}>
                ×
              </span>
            </div>
            <div className="offcanvas-content mt-4 ">
              <a href="/" className="mt-2 fs-3 mx-3 fw-bold  text-white">
                House
              </a>
              <a
                href="#!"
                onClick={toggleSolutions}
                className="accordion-trigger fw-bold fs-3 mx-3 mt-4 text-white"
              >
                Solutions
                <span className="icon">
                  <i
                    className={`bi mx-2 fs-2 ${
                      isSolutionsOpen ? "bi-chevron-up" : "bi-chevron-down"
                    }`}
                  ></i>
                </span>
              </a>
              {isSolutionsOpen && (
                <div className="accordion">
                  <div
                    className="accordion-header  "
                    onClick={toggleNestedServices}
                  >
                    Services
                    <span className="icon">
                      <i
                        className={`bi mx-2 fs-2 ${
                          isNestedOpen ? "bi-chevron-up" : "bi-chevron-down"
                        }`}
                      ></i>
                    </span>
                  </div>
                  {isNestedOpen && (
                    <div className="accordion-content">
                      <ul className="list-unstyled">
                        <a href="/ModernSupplySolutions">
                          <li className="mt-3 fs-4 submenu_color">
                            Modern Supply Chain Solutions
                          </li>
                        </a>
                        <a href="/DashBoard">
                          <li className="mt-3 fs-4 submenu_color">
                            Dashboard Experience
                          </li>
                        </a>
                        <a href="/ProblemSolvingsNew">
                          <li className="mt-3 fs-4 submenu_color">
                            Problem Solving at Stephen's
                          </li>
                        </a>
                      </ul>
                    </div>
                  )}
                </div>
              )}

              {isSolutionsOpen && (
                <div className="accordion mt-4">
                  <div
                    className="accordion-header "
                    onClick={toggleNestedIndustries}
                  >
                    Industries
                    <span className="icon">
                      <i
                        className={`bi mx-2 fs-2 ${
                          isNestedOpenIndustries
                            ? "bi-chevron-up"
                            : "bi-chevron-down"
                        }`}
                      ></i>
                    </span>
                  </div>
                  {isNestedOpenIndustries && (
                    <div className="accordion-content">
                      <ul className="list-unstyled">
                        <a href="/Banking">
                          <li className="mt-3 fs-4 submenu_color">Banking</li>
                        </a>
                        <a href="/HealthCarePage">
                          <li className="mt-3 fs-4 submenu_color">
                            Health Care
                          </li>
                        </a>
                        <a href="/OilAndGas">
                          <li className="mt-3 fs-4 submenu_color">
                            Oil and Gas
                          </li>
                        </a>
                        <a href="/">
                          <li className="mt-3 fs-4 submenu_color">Retail</li>
                        </a>
                        <a href="/ViewAll">
                          <li className="mt-3 fs-4 submenu_color">
                            View all industries
                          </li>
                        </a>
                      </ul>
                    </div>
                  )}
                </div>
              )}

              <a
                href="#!"
                className="accordion-trigger mt-4 fs-3 mx-3 fw-bold text-white insight-link"
                onClick={toggleInsight}
              >
                Insights{" "}
                <span className="icon">
                  <i
                    className={`bi mx-2 fs-2 ${
                      isInsightOpen ? "bi-chevron-up" : "bi-chevron-down"
                    }`}
                  ></i>
                </span>
              </a>

              {isInsightOpen && (
                <div className="accordion">
                  <div
                    className="accordion-header "
                    onClick={toggleNestedComprehen}
                  >
                    Comprehension
                    <span className="icon">
                      <i
                        className={`bi mx-2 fs-2 ${
                          isNestedComprehenOpen
                            ? "bi-chevron-up"
                            : "bi-chevron-down"
                        }`}
                      ></i>
                    </span>
                  </div>
                  {isNestedComprehenOpen && (
                    <div className="accordion-content">
                      <ul className="list-unstyled">
                        <a href="/WhyStephensAnyts">
                          <li className="mt-3 fs-4 submenu_color">
                            Why Stephen's Analytics
                          </li>
                        </a>
                        <a href="/HospitalDetails">
                          <li className="mt-3 fs-4 submenu_color">
                            Simpson's Paradox
                          </li>
                        </a>
                      </ul>
                    </div>
                  )}
                </div>
              )}

              {isInsightOpen && (
                <div className="accordion mt-4">
                  <div className="accordion-header" onClick={toggleNestedBlog}>
                    Blog{" "}
                    <span className="icon">
                      <i
                        className={`bi mx-2 fs-2 ${
                          isNestedOpenBlog ? "bi-chevron-up" : "bi-chevron-down"
                        }`}
                      ></i>
                    </span>
                  </div>
                  {isNestedOpenBlog && (
                    <div className="accordion-content">
                      <ul className="list-unstyled">
                        <a href="/AdoptingGenAI">
                          <li className="mt-3 fs-4 submenu_color">
                            What CEOs Need to Know
                          </li>
                        </a>
                        <a href="/">
                          <li className="mt-3 fs-4 submenu_color">
                            About the Cost of adopting
                          </li>
                        </a>
                        <a href="/AdoptingGenAI">
                          <li className="mt-3 fs-4 submenu_color">GenAi</li>
                        </a>
                      </ul>
                    </div>
                  )}
                </div>
              )}

              <a
                href="#!"
                className="accordion-trigger mt-4 fs-3 mx-3 mx-3 fw-bold text-white"
                onClick={toggleContact}
              >
                Contact Us{" "}
                <span className="icon">
                  <i
                    className={`bi mx-2 fs-2 ${
                      isContactOpen ? "bi-chevron-up" : "bi-chevron-down"
                    }`}
                  ></i>
                </span>
              </a>

              {isContactOpen && (
                <div className="accordion ">
                  <div
                    className="accordion-header "
                    onClick={toggleNestedAbout}
                  >
                    About Us
                    <span className="icon">
                      <i
                        className={`bi mx-2 fs-2 ${
                          isNestedAbout ? "bi-chevron-up" : "bi-chevron-down"
                        }`}
                      ></i>
                    </span>
                  </div>
                  {isNestedAbout && (
                    <div className="accordion-content">
                      <ul className="list-unstyled">
                        <a href="/">
                          <li className="mt-3 fs-4 submenu_color">
                            About the Company
                          </li>
                        </a>
                        <a href="/Clientele">
                          <li className="mt-3 fs-4 submenu_color">
                            Clientele{" "}
                          </li>
                        </a>
                        <a href="/TopNews">
                          <li className="mt-3 fs-4 submenu_color">Top News </li>
                        </a>
                        <a href="/ModernSupplySolutions">
                          <li className="mt-3 fs-4 submenu_color">
                            Download our Play Book{" "}
                          </li>
                        </a>
                      </ul>
                    </div>
                  )}
                </div>
              )}

              {isContactOpen && (
                <div className="accordion mt-3">
                  <div
                    className="accordion-header"
                    onClick={toggleNestedCarrier}
                  >
                    Carriers{" "}
                    <span className="icon">
                      <i
                        className={`bi mx-2 fs-2 ${
                          isNestedOpenCarrier
                            ? "bi-chevron-up"
                            : "bi-chevron-down"
                        }`}
                      ></i>
                    </span>
                  </div>
                  {isNestedOpenCarrier && (
                    <div className="accordion-content">
                      <ul className="list-unstyled">
                        <a href="/ApplyJobs">
                          <li className="mt-3 fs-4 submenu_color">
                            Apply for Jobs
                          </li>
                        </a>
                      </ul>
                    </div>
                  )}
                </div>
              )}
              {isContactOpen && (
                <div className="accordion mt-3">
                  <div className="accordion-header" onClick={toggleNestedHelp}>
                    Help{" "}
                    <span className="icon">
                      <i
                        className={`bi mx-2 fs-2 ${
                          isNestedOpenHelp ? "bi-chevron-up" : "bi-chevron-down"
                        }`}
                      ></i>
                    </span>
                  </div>
                  {isNestedOpenHelp && (
                    <div className="accordion-content ">
                      <ul className="list-unstyled">
                        <a href="/DataPolicy">
                          <li className="mt-3 fs-4 submenu_color">
                            Data Protection Pollicy
                          </li>
                        </a>
                        <a href="/FAQs">
                          <li className="mt-3 fs-4 submenu_color">FAQs</li>
                        </a>
                        <a href="/ContactUs">
                          <li className="mt-3 fs-4 submenu_color">
                            Contact Us
                          </li>
                        </a>
                      </ul>
                    </div>
                  )}
                </div>
              )}

            <a href="/ReadMe"> <button className="mt-5">Download Playbook</button></a> 
            </div>
          </div>

          <div className="toggle-button" onClick={toggleMenu}>
            ☰
          </div>

          <div className="large_screen text-center">
            <div className="atagmove">
              <a href="/" className="home_btn">
                HOUSE
              </a>
              <a
                href="#"
                onClick={() => handleClick("insight")}
                className={activeContent === "insight" ? "active" : ""}
              >
                INSIGHTS
              </a>
              <a
                href="#"
                onClick={() => handleClick("solution")}
                className={activeContent === "solution" ? "active" : ""}
              >
                SOLUTIONS
              </a>

              <a
                href="#"
                onClick={() => handleClick("contact")}
                className={activeContent === "contact" ? "active" : ""}
              >
                CONTACT US
              </a>
            </div>
            <a href="/ReadMe">
              {" "}
              <button>Download Playbook</button>
            </a>
          </div>

          {/* Overlay for Contact Us */}
          {activeContent && (
            <div className="overlay" onClick={closeOverlay}>
              <div
                className="overlay-content"
                onClick={(e) => e.stopPropagation()}
              >
                {" "}
                {/* Prevent click event from closing the overlay */}
                <span className="close-button" onClick={closeOverlay}>
                  ×
                </span>
                {activeContent === "contact" && (
                  <div className="container-fluid mt-1 contain2">
                    <div className="row row1">
                      <div className="col-lg-5 mb-4">
                        <div className="p-4">
                          <h2 className="h2-edit fw-bold">About Us</h2>
                          <ul className="list-unstyled items">
                            <li>
                              <a href="/Clientele">Clientele</a>
                            </li>
                            <li>
                              <a href="/TopNews">Top News</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-7 mb-4">
                        <div className="p-4">
                          <h2 className="h2-edit fw-bold">Help</h2>
                          <ul className="list-unstyled items">
                            <li>
                              <a href="/ApplyJobs">Apply for jobs</a>
                            </li>
                            <li>
                              <a href="/DataPolicy">Data Protection Policy</a>
                            </li>
                            <li>
                              <a href="/">FAQs</a>
                            </li>
                            <li>
                              <a href="/ContactUs">Contact Us</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeContent === "insight" && (
                  <div className="container-fluid mt-1 contain2">
                    <div className="row row1">
                      <div className="col-lg-5 mb-1">
                        <div className="p-4">
                          <h2 className="h2-edit fw-bold">Comprehension</h2>
                          <ul className="list-unstyled items">
                            <li>
                              <a href="/WhyStephensAnyts">
                                Why Stephens's Analytics
                              </a>
                            </li>
                            <li>
                              <a href="/HospitalDetails"> Simpson's Paradox</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-7 mb-4">
                        <div className="p-4">
                          <h2 className="h2-edit fw-bold">Blogs</h2>
                          <ul className="list-unstyled items">
                            <li>
                              <a href="/AdoptingGenAI">
                                What CEOs Need to Know About the Costs of
                                Adopting GenAI
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeContent === "solution" && (
                  <div className="container-fluid mt-1 contain2">
                    <div className="row row1">
                      <div className="col-lg-5 mb-1">
                        <div className="p-4">
                          <h2 className="h2-edit fw-bold">Services</h2>
                          <ul className="list-unstyled items">
                            <li>
                              <a href="/ModernSupplySolutions">
                                Modern Supply Chain Solutions
                              </a>
                            </li>
                            <li>
                              <a href="/ProblemSolvingsNew">
                                Problem Solving at Stephen's
                              </a>
                            </li>
                            <li>
                              <a href="/DashBoard">DashBoard Experience </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-7 mb-4">
                        <div className="p-4">
                          <h2 className="h2-edit fw-bold">Industries</h2>
                          <ul className="list-unstyled items">
                            <li>
                              <a href="/Banking">Banking</a>
                            </li>
                            <li>
                              <a href="/HealthCarePage">Health Care</a>
                            </li>
                            <li>
                              <a href="/OilAndGas">Oil & Gas</a>
                            </li>
                            <li>
                              <a href="/ViewAll">View all industries</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
