import React from "react";
import "./Card.css";
import cardimg1 from "../../Home/Card/Assets/1.mp4";
import cardimg2 from "../../Home/Card/Assets/2.mp4";
import cardimg3 from "../../Home/Card/Assets/3.mp4";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

const Card = () => {
  return (
    <div className="container-fluid ">
      {/* Show a regular row on large screens */}
      <div className="row d-none d-lg-flex justify-content-center align-items-stretch ">
        <div className="col-lg-1"></div>
        {[cardimg1, cardimg2, cardimg3].map((src, index) => (
          <div className="col-lg-3 col-md-6 col-12 mb-4 " key={index}>
            <div className="card   border-0 shadow-md"   >
              <video
                src={src}
                className="card-img-top"
                alt="..."
                loop
                autoPlay
                muted
              />
              <div className="card-body1">
                <h5 className="card-title">
                  {index === 0
                    ? "Improve Decision Making"
                    : index === 1
                    ? "Automate Repetitive Tasks"
                    : "Identify New Opportunities"}
                </h5>
                <p className="card-text mt-4">
                  {index === 0
                    ? "AI algorithms can uncover patterns, trends, and correlations that may be difficult for humans to identify manually. This leads to more accurate and comprehensive data analysis, providing deeper insights and enabling data-driven decision-making."
                    : index === 1
                    ? "AI automates repetitive and time-consuming tasks. Thus, data experts can focus on interpreting results, formulating strategies, and generating innovative ideas. Automation also reduces the risk of human error, leading to more reliable and consistent results."
                    : "It can uncover hidden opportunities and potential areas for the growth of new business. AI algorithms can identify market trends, customer segments, and emerging patterns that may not be apparent through traditional analysis."}
                </p>
              </div>
            </div>
          </div>
        ))}
        <div className="col-lg-1"></div>
      </div>

      {/* Show Swiper slider on small screens */}
      <div className="d-lg-none pt-5">
        <Swiper
          spaceBetween={20}
          pagination={{ clickable: true }}
          modules={[Pagination]}
        >
          {[cardimg1, cardimg2, cardimg3].map((src, index) => (
            <SwiperSlide key={index}>
              <div className="card border-0 shadow-md" >
                <video
                  src={src}
                  className="card-img-top"
                  alt="..."
                  loop
                  autoPlay
                  muted
                />
                <div className="card-body1">
                  <h5 className="card-title">
                    {index === 0
                      ? "Improve Decision Making"
                      : index === 1
                      ? "Automate Repetitive Tasks"
                      : "Identify New Opportunities"}
                  </h5>
                  <p className="card-text mt-4">
                    {index === 0
                      ? "AI algorithms can uncover patterns, trends, and correlations that may be difficult for humans to identify manually. This leads to more accurate and comprehensive data analysis, providing deeper insights and enabling data-driven decision-making."
                      : index === 1
                      ? "AI automates repetitive and time-consuming tasks. Thus, data experts can focus on interpreting results, formulating strategies, and generating innovative ideas. Automation also reduces the risk of human error, leading to more reliable and consistent results."
                      : "It can uncover hidden opportunities and potential areas for the growth of new business. AI algorithms can identify market trends, customer segments, and emerging patterns that may not be apparent through traditional analysis."}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Card;
